// import BackupTableIcon from '@mui/icons-material/BackupTable'
// import {
// 	Box,
// 	Button,
// 	CircularProgress,
// 	Divider,
// 	Grid,
// 	Stack,
// 	Typography
// } from '@mui/material'
// import moment from 'moment'
// import {
// 	FC,
// 	useCallback,
// 	useContext,
// 	useEffect,
// 	useMemo,
// 	useState
// } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { useParams } from 'react-router-dom'
// import { CubeContext } from '@cubejs-client/react'
// import { CubejsApi } from '@cubejs-client/core'
// import { flow, groupBy, orderBy } from 'lodash'
// import { AppContext } from '../../../../../App'
// import { loadSnackbar } from '../../../../../Utils/util'
// import AddCommentButton from '../Component/AddComment'
// import { LGVHealthContext } from '../Component/Context'
// import NavBar from '../Component/NavBar'
// import NavQualLegend from '../Component/NavQualLegend'
// import TimeFilter from '../Component/TimeFilter'
// import { SingleLgv, HeatmapRespData, Annotation } from '../Helpers/interfaces'
// import {
// 	timeFilterRanges,
// 	transformData,
// 	transformToHeatMapData,
// 	transformToLGVOverview
// } from '../Helpers/utils'
// import AssetSelect from './Components/AssetSelect'
// import { useQueryCubeFetch } from '../Helpers/hooks'
// import ViewAnnotations from '../Component/ViewAnnotations'
// import DynamicPlantLayoutWrapper from './Components/DynamicPlantLayoutWrapper'
// import { DetailPage as useStyles } from './helpers/styles'
// import LgvNavQualityOverTime from './Components/QualityOverTime'
// import { getLGVAnnotations } from '../InverterSystemHealth/helpers/services'
// import {
// 	USECASE_NAME,
// 	QUALITY_OVER_TIME_TYPE,
// 	LGV_NAV_QUALITY_CUBE_DATA_FETCH_DETAIL,
// 	LGV_NAV_QUALITY_CUBE_DATA_FETCH_LANDING
// } from './helpers/constants'
// // import { fetchRemovedLGVIdsOfPlant } from '../Helpers/services'
// import { formChartData } from './helpers/utils'
// import NavigationQualityTimeSeriesChart from './Components/NavigationQualityTimeSeriesChart'

// interface NumberedObject<Type> {
// 	[key: number]: Type
// }

// const defaultStartDate = moment().subtract(12, 'hours').toDate()

// const defaultEndDate = new Date()

// const axisTitle: any = {
// 	xAxis: 'Time',
// 	yAxis: 'Median Navigation Quality'
// }

const DetailsPage = () => {
	// const classes = useStyles()
	// const { assetId } = useParams()

	// const lgvStoreData = useSelector(
	// 	(state: any) => state.lgvHealth.historicalData
	// )

	// const plantsList = useSelector((state: any) => state.plantsList)
	// const seedsList = useSelector((state: any) => state.seedsList)

	// const dispatch = useDispatch()

	// const [isLoading, setIsLoading] = useState<boolean>(true)
	// const [timeMachineEnabled, setTimeMachineEnabled] = useState<boolean>(false)
	// const [openDialog, setOpenDialog] = useState<boolean>(false)
	// const [annotationLoader, setAnnotationLoader] = useState<boolean>(true)

	// const [startTimeDate, setStartTimeDate] = useState<Date>(defaultStartDate)
	// const [endTimeDate, setEndTimeDate] = useState<Date>(defaultEndDate)

	// const [clickedLink, setClickedLink] = useState<number>(0)
	// const [selectedAssets, setSelectedAssets] = useState<string[]>([assetId])
	// const [removedLgvNums, setRemovedLgvNums] = useState<any>([])

	// const [allLgvData, setAllLgvData] =
	// 	useState<NumberedObject<SingleLgv>[]>(lgvStoreData)

	// const [HeatMapData, setHeatMapData] = useState<
	// 	NumberedObject<HeatmapRespData>[]
	// >([])

	// const [plantLayoutLgvRecords, setPlantLayoutLgvRecords] = useState<{
	// 	[lgvId: string]: HeatmapRespData[]
	// }>({})

	// const formattedSelectedAssetsData = useMemo(() => {
	// 	const formattedLgvId: any = []

	// 	selectedAssets.forEach((asset: string) => {
	// 		formattedLgvId.push(asset?.replace(/\s/g, ''))
	// 	})

	// 	return formattedLgvId
	// }, [selectedAssets])

	// const [allAnn, setAllAnn] = useState<Annotation[]>([])
	// const [navQualityRecords, setNavQualityRecords] = useState<any>([])
	// const [chartData, setChartData] = useState<any>([])

	// const { selectedPlant } = useContext(LGVHealthContext)
	// const { selectedPlantForCube, setSelectedPlantForCube }: any =
	// 	useContext(AppContext)
	// const cubejsApi: CubejsApi = useContext(CubeContext).cubejsApi
	// const plantCode: string = selectedPlant.plant_code
	// const reportName = `${assetId} Report`

	// async function fetchRemovedLgvs() {
	// 	try {
	// 		// API call to get selected plant removed lgvIds
	// 		// const lgvNumbers = await fetchRemovedLGVIdsOfPlant(selectedPlant)
	// 		// setRemovedLgvNums(JSON.parse(lgvNumbers))
	// 		setRemovedLgvNums([])
	// 	} catch (error) {
	// 		console.error('Error fetching LGV data:', error)
	// 	}
	// }

	// useEffect(() => {
	// 	fetchRemovedLgvs()
	// }, [selectedPlant])
	// // Find LGVHealth configuration
	// const lgvHealthConfig = useMemo(() => {
	// 	return seedsList.seeds.find((seed: any) => {
	// 		return seed.seed_key_name === 'LGVHealth'
	// 	})?.seed_data_value
	// }, [seedsList.seeds])

	// const lgvNavQualityConfigValues: any = useMemo(() => {
	// 	// If LGVHealth configuration exists, filter LGV_NAV_QUALITY config
	// 	if (!lgvHealthConfig) return

	// 	const lgvNavQualityConfig = lgvHealthConfig.filter(
	// 		(item: any) => item?.key === 'LGV_NAV_QUALITY'
	// 	)

	// 	// Parse LGV_NAV_QUALITY config values if found
	// 	if (lgvNavQualityConfig.length > 0) {
	// 		return JSON.parse(lgvNavQualityConfig[0]?.value)
	// 	}
	// }, [lgvHealthConfig])

	// const navQualitySegmentConfig =
	// 	lgvNavQualityConfigValues['Navigation_Quality_Over_Time']

	// const navHeatMapConfig = lgvNavQualityConfigValues['Navigation_Heat_Map']

	// const handleTimeMachineChange = () => {
	// 	setTimeMachineEnabled(!timeMachineEnabled)
	// }

	// // Function to open the dialog
	// const handleOpenDialog = () => setOpenDialog(true) // Fetch or set annotations data here

	// // Function to close the dialog
	// const handleCloseDialog = () => setOpenDialog(false)

	// const handleSelectedAssetChange = (newSelectedAssets: string[]) =>
	// 	setSelectedAssets(newSelectedAssets)

	// const assetsAvailable = useMemo<string[]>(() => {
	// 	const assetsAvailable: any = []
	// 	navQualityRecords.forEach((item: any) => {
	// 		if (
	// 			!removedLgvNums?.includes(item?.lgvId) &&
	// 			!assetsAvailable?.includes('LGV ' + item?.lgvId)
	// 		) {
	// 			assetsAvailable.push('LGV ' + item?.lgvId)
	// 		}
	// 	})
	// 	return assetsAvailable
	// }, [navQualityRecords])

	// const groupedData = useMemo(() => {
	// 	const sortedNavQualityRecords = orderBy(
	// 		navQualityRecords.filter((item: any) =>
	// 			selectedAssets?.includes('LGV ' + item?.lgvId)
	// 		),
	// 		'lgvId',
	// 		'asc'
	// 	)

	// 	return sortedNavQualityRecords.reduce((acc: any, curr: any): any => {
	// 		const group: any = acc.find(
	// 			(group: any) =>
	// 				group.length > 0 && group[0].assetName === curr.assetName
	// 		)
	// 		if (group) {
	// 			group.push(curr)
	// 		} else {
	// 			acc.push([curr])
	// 		}

	// 		return acc
	// 	}, [])
	// }, [navQualityRecords, selectedAssets])

	// const fetchAnnotations = useCallback(async () => {
	// 	try {
	// 		const assetIdSplit = assetId.split(' ')
	// 		const assetIdNum = assetIdSplit[1]
	// 		setIsLoading(true)
	// 		const response = await getLGVAnnotations(
	// 			selectedPlant,
	// 			assetIdNum,
	// 			QUALITY_OVER_TIME_TYPE,
	// 			startTimeDate,
	// 			endTimeDate,
	// 			USECASE_NAME
	// 		)

	// 		const allAnnotations = response.data

	// 		setAllAnn(allAnnotations)
	// 		setAnnotationLoader(false)
	// 	} catch (error) {
	// 		dispatch(loadSnackbar('Error Fetching Annotations'))
	// 	} finally {
	// 		setIsLoading(false)
	// 	}
	// }, [assetId, selectedPlant, dispatch])

	// useEffect(() => {
	// 	setSelectedPlantForCube(selectedPlant.plant_code)
	// }, [selectedPlant])

	// const groupByLGVId = (plantLayoutLgvRecords: any) =>
	// 	groupBy(plantLayoutLgvRecords, 'lgvId')

	// const {
	// 	cubeResponse: cubeResponseOld,
	// 	isCubeDataLoading,
	// 	cubeDataFetch: cubeDataFetchOld,
	// 	isCubeDataRefetching
	// } = useQueryCubeFetch({
	// 	uniqueKey: LGV_NAV_QUALITY_CUBE_DATA_FETCH_DETAIL,
	// 	plant_code: selectedPlant?.plant_code,
	// 	selectedPlantForCube,
	// 	useCaseType: 'nav_quality_detail',
	// 	startTimeDate,
	// 	endTimeDate,
	// 	cubejsApi,
	// 	dispatch
	// })

	// const {
	// 	cubeResponse,
	// 	// isCubeDataLoading,
	// 	cubeDataFetch
	// 	// isCubeDataRefetching
	// } = useQueryCubeFetch({
	// 	uniqueKey: LGV_NAV_QUALITY_CUBE_DATA_FETCH_LANDING,
	// 	plant_code: selectedPlant?.plant_code,
	// 	selectedPlantForCube,
	// 	useCaseType: 'nav_quality_landing',
	// 	startTimeDate: startTimeDate,
	// 	endTimeDate: endTimeDate,
	// 	cubejsApi,
	// 	dispatch
	// })

	// useEffect(() => {
	// 	if (!cubeResponseOld.length) return

	// 	const handleAllLgvData = flow(transformData, setAllLgvData)
	// 	handleAllLgvData(cubeResponseOld)

	// 	setHeatMapData(transformToHeatMapData(cubeResponseOld) as any[])

	// 	const handlePlantLayoutLgvRecords = flow(
	// 		transformToHeatMapData,
	// 		groupByLGVId,
	// 		setPlantLayoutLgvRecords
	// 	)

	// 	handlePlantLayoutLgvRecords(cubeResponseOld)
	// }, [cubeResponseOld])

	// useEffect(() => {
	// 	if (!cubeResponse.length) {
	// 		setNavQualityRecords([])
	// 	} else {
	// 		const cubeName = `${selectedPlant?.plant_code?.trim()}FiveMinAgg`
	// 		setNavQualityRecords(transformToLGVOverview(cubeResponse, cubeName))
	// 	}
	// }, [cubeResponse])

	// useEffect(() => {
	// 	fetchAnnotations()
	// }, [fetchAnnotations])

	// useEffect(() => {
	// 	setIsLoading(Boolean(plantsList.plants.length))
	// }, [plantsList])

	// useEffect(() => {
	// 	dispatch({
	// 		type: 'LOAD_HISTORICAL_DATA',
	// 		payload: { historicalData: allLgvData }
	// 	})
	// }, [allLgvData, dispatch])

	// useEffect(() => {
	// 	if (groupedData?.length && selectedAssets?.length) {
	// 		setChartData(
	// 			formChartData(groupedData, 'Median Navigation Quality Chart')
	// 		)
	// 	} else {
	// 		setChartData([])
	// 	}
	// }, [groupedData, selectedAssets])

	// useEffect(() => {
	// 	cubeDataFetchOld()
	// 	cubeDataFetch()
	// }, [startTimeDate, endTimeDate, plantCode])

	return (
		<>Detail page</>
		// <>
		// 	{isLoading || isCubeDataLoading || isCubeDataRefetching ? (
		// 		<CircularProgress style={{ margin: '20% 45%' }} />
		// 	) : (
		// 		<Box className={[classes.criticalControlSec].join(' ')}>
		// 			<Grid
		// 				container
		// 				direction='row'
		// 				justifyContent='between'
		// 				alignItems='center'
		// 				spacing={2}
		// 				rowSpacing={4}
		// 				style={{
		// 					padding: '40px 20px 20px'
		// 				}}
		// 			>
		// 				<NavBar
		// 					page={reportName}
		// 					arrow={true}
		// 					navigationUrl='/usecase/lgv-nav'
		// 				/>
		// 				<Grid item xs={12} sm={12} md={12}>
		// 					<Divider />
		// 				</Grid>
		// 			</Grid>
		// 			<Grid container spacing={2}>
		// 				<Grid
		// 					marginLeft='3em'
		// 					maxHeight='5px'
		// 					marginTop='1.4em'
		// 					padding='none'
		// 					marginBottom='2em'
		// 				>
		// 					<TimeFilter
		// 						selectedPlant={selectedPlant}
		// 						timeFilterRanges={timeFilterRanges}
		// 						startDateTime={startTimeDate}
		// 						setStartDateTime={setStartTimeDate}
		// 						endDateTime={endTimeDate}
		// 						setEndDateTime={setEndTimeDate}
		// 					/>
		// 				</Grid>
		// 				<Grid>
		// 					<AssetSelect
		// 						assetsAvailable={assetsAvailable}
		// 						onChange={handleSelectedAssetChange}
		// 						selectedAssets={selectedAssets}
		// 					/>
		// 				</Grid>
		// 			</Grid>
		// 			<Grid
		// 				item
		// 				xs={12}
		// 				sx={{
		// 					padding: '20px'
		// 				}}
		// 				className={[classes.width100, classes.posRelative].join(' ')}
		// 			>
		// 				<DynamicPlantLayoutWrapper
		// 					plantLayoutLgvRecords={plantLayoutLgvRecords}
		// 					timeSliderStart={startTimeDate}
		// 					timeSliderEnd={endTimeDate}
		// 					timeMachineEnabled={timeMachineEnabled}
		// 					handleTimeMachineChange={handleTimeMachineChange}
		// 					plantCode={plantCode}
		// 					HeatMapData={HeatMapData}
		// 					navHeatMapConfig={navHeatMapConfig}
		// 					formattedSelectedAssetsData={formattedSelectedAssetsData}
		// 				/>
		// 				<Box
		// 					id='parent-modal'
		// 					sx={{
		// 						background: '#293368',
		// 						padding: '10px',
		// 						borderRadius: '8px',
		// 						marginBottom: '20px',
		// 						position: 'relative',
		// 						width: '100%',
		// 						height: '100%'
		// 					}}
		// 				>
		// 					<Grid item>
		// 						<Box
		// 							sx={{
		// 								display: 'flex',
		// 								justifyContent: 'space-between',
		// 								alignItems: 'center',
		// 								position: 'relative',
		// 								width: '100%',
		// 								height: '100%'
		// 							}}
		// 						>
		// 							<Typography
		// 								p={1}
		// 								sx={{
		// 									color: '#ffffff',
		// 									fontSize: '18px',
		// 									fontStyle: 'Roboto',
		// 									textTransform: 'capitalize',
		// 									fontWeight: 'bold'
		// 								}}
		// 							>
		// 								{navQualitySegmentConfig.chartTitle ||
		// 									'Nav Quality Over Time'}
		// 							</Typography>
		// 							<div style={{ display: 'flex', alignItems: 'center' }}>
		// 								<Stack direction='row' alignItems='center' spacing={1}>
		// 									<AddCommentButton parentContainerId='parent-modal' />
		// 									<Button
		// 										variant='outlined'
		// 										color='primary'
		// 										onClick={handleOpenDialog}
		// 										sx={{ width: '100%' }}
		// 									>
		// 										<BackupTableIcon />
		// 										<Typography sx={{ mx: 1 }}>View Comments</Typography>
		// 									</Button>
		// 								</Stack>
		// 							</div>
		// 						</Box>
		// 						<LgvNavQualityOverTime
		// 							legendItems={navQualitySegmentConfig.ranges}
		// 							secondaryLegend={
		// 								navQualitySegmentConfig.secondaryLegend || false
		// 							}
		// 							assetId={assetId}
		// 							reportName={reportName}
		// 							clickedLink={clickedLink}
		// 							annotations={allAnn}
		// 							setAnnotations={setAllAnn}
		// 						/>

		// 						<NavQualLegend
		// 							legendTitle={
		// 								navQualitySegmentConfig.legendTitle || 'Threshold'
		// 							}
		// 							legendItems={navQualitySegmentConfig.ranges || []}
		// 						/>
		// 					</Grid>
		// 				</Box>

		// 				<Box
		// 					id='parent-modal'
		// 					sx={{
		// 						background: '#293368',
		// 						padding: '10px',
		// 						borderRadius: '8px',
		// 						marginBottom: '20px',
		// 						position: 'relative',
		// 						width: '100%',
		// 						height: '100%'
		// 					}}
		// 				>
		// 					<NavigationQualityTimeSeriesChart
		// 						chartData={chartData}
		// 						selectedPlant={selectedPlant}
		// 						axisTitle={axisTitle}
		// 					/>
		// 				</Box>
		// 			</Grid>
		// 		</Box>
		// 	)}

		// 	<ViewAnnotations
		// 		open={openDialog}
		// 		onClose={handleCloseDialog}
		// 		annotations={allAnn}
		// 		title='Comments'
		// 		closeButton='Close'
		// 		pageName='LGV Nav Detail Page'
		// 		setFilteredAnnotations={setAllAnn}
		// 		setClickedLink={setClickedLink}
		// 		setAnnotations={() => undefined}
		// 		loader={annotationLoader}
		// 	/>
		// </>
	)
}
// interface Annotation {
//   _id: string;
//   message: string;
//   reportName: string;
//   x: string;
//   y: string;
//   userId: string;
//   tagType: string;
//   timestamp: string;
//   type: string;
//   plantId?: string;
//   title?: string;
//   scatter_plot_type?: string;
//   use_case_name?: string;
// }

export default DetailsPage;
