import React, { Fragment } from "react";
import { Paper, Button, Typography } from "@material-ui/core";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import {Box} from "@mui/material";

const useStyle = makeStyles((theme) => ({
  button: {
    "&.MuiButton-root": {
      width: "0px",
    },
  },
  typographyText: {
    "&.MuiTypography-root": {
      color: "#FFF",
      fontWeight: "600",
    },
  },
  success: {
    height: 26,
    margin: -7,
    display: "flex",
    border: "1px solid green",
    background: "lightgreen",
    content: "Success",
  },
  error: {
    height: 26,
    margin: -7,
    display: "flex",
    flexGrow: 1,
    border: "1px solid red",
    background: "rgb(253, 148, 165)",
    content: "failure",
  },
  info: {
    "&.MuiPaper-root": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "#289BCC",
      padding: "8px",
      marginRight: "40px",
    },
  },
}));

function Banner(props: any) {
  const [show, setShow] = React.useState<false>(props.open);
  const classes = useStyle();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: "LOAD_SNACKBAR",
      payload: {
        message: "Please Update your Mobile Number to close",
        type: "error",
        open: true,
      },
    });
  };

  return (
    <Fragment>
      {show ? (
        <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Paper className={props.type === "info" ? classes.info : classes.error}>
          <IconButton aria-label="delete" size="small" disableRipple>
            <ErrorOutlineRoundedIcon />
          </IconButton>
          <Typography variant="subtitle1" className={classes.typographyText}>
            {props.message}
          </Typography>
          <IconButton aria-label="delete" onClick={handleClose} size="small">
            <CloseRoundedIcon />
          </IconButton>
        </Paper>
        </Box>
      ) : null}
    </Fragment>
  );
}

export default Banner;
